<template>
  <AppPageCard>
    <template #card-title> Teacher Yearly Attendance Report</template>
    <template #card-toolbar>
      <AppRefreshButton @refresh-clicked="fetch"></AppRefreshButton>
    </template>
    <div
      class="
        d-flex
        flex-column flex-lg-row
        bg-light-primary
        p-3
        rounded
        justify-content-between
      "
    >
      <AppFieldYearSelector @clickedSearch="changeDate"></AppFieldYearSelector>
      <TeacherMonthlyExportControl></TeacherMonthlyExportControl>
    </div>

    <!-- begin: export table   -->
    <SchoolEmployeeMonthlyAttendanceExportTable
      :is-busy="isBusy"
      :items="items"
      :fields="fields"
      :leaves="modifiedLeaves"
      :aways="modifiedAways"
    ></SchoolEmployeeMonthlyAttendanceExportTable>
    <!-- end: export table   -->

    <!-- start: pagination    -->
    <div class="d-flex justify-content-end" v-if="items.length">
      <b-pagination
        pills
        size="lg"
        class="pt-5"
        @change="changePage"
        v-model="pagination.current_page"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
      ></b-pagination>
    </div>
    <!-- end: pagination    -->
    <div class="my-4">
      <SchoolEmployeeExportLegend
        is-row
        :leaves="leaves"
        :aways="aways"
      ></SchoolEmployeeExportLegend>
    </div>
  </AppPageCard>
</template>

<script>
import { mapGetters } from "vuex";
import i18n from "@/core/plugins/vue-i18n";
import dayjs from "dayjs";
import ColumnService from "@/modules/school/services/management/attendance/school_employee_export_column.service";
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import SchoolEmployeeMonthlyAttendanceExportTable from "@/modules/school/components/management/attendance/SchoolEmployeeMonthlyAttendanceExportTable";
import TeacherMonthlyExportControl from "@/modules/school/components/management/attendance/teacher/TeacherMonthlyExportControl";
import SchoolEmployeeExportLegend from "@/modules/school/components/management/attendance/utility/SchoolEmployeeExportLegend";
import AppRefreshButton from "@/modules/core/components/button/AppRefreshButton";
import AppFieldYearSelector from "@/modules/core/components/utility/date/AppFieldYearSelector";
export default {
  name: "TeacherExportYearlyAttendancePage",
  components: {
    AppFieldYearSelector,
    AppRefreshButton,
    SchoolEmployeeExportLegend,
    TeacherMonthlyExportControl,
    SchoolEmployeeMonthlyAttendanceExportTable,
    AppPageCard,
  },
  data() {
    return {
      isBusy: false,
      fields: [
        { key: "index", label: "#" },
        { key: "employee", label: i18n.t("EMPLOYEE.HEADER") },
        { key: "employee.attendance.present_count", label: "W" },
        { key: "employee.attendance.absent_count", label: "AB" },
        { key: "employee.attendance.holiday_count", label: "Ho" },
      ],
      modifiedLeaves: [],
      modifiedAways: [],
    };
  },
  mounted() {
    this.fetch();
    this.fetchSchoolAways();
  },
  methods: {
    /**
     * Fetch export list
     */
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchTeacherYearlyAttendanceExportList")
        .finally(() => (this.isBusy = false));
    },

    fetchSchoolAways() {
      this.$store.dispatch("fetchSchoolAwayDetailList").then((res) => {
        console.log(res.data);
        this.appendAwayFields();
        this.fetchSchoolLeaves();
      });
    },

    /**
     * Fetch school leaves
     */
    fetchSchoolLeaves() {
      this.$store.dispatch("fetchSchoolLeaveDetailList").then((res) => {
        console.log(res.data);
        this.appendFields();
      });
    },

    appendAwayFields() {
      console.log("Aways: ", this.aways);
      let colAways = [];
      colAways = ColumnService.generateAwayCols(this.aways);
      this.fields.push(...colAways);
      this.modifiedAways = colAways;
    },

    /**
     * Append to existing column fields to
     *
     * 1. Create columns of leave with leave id as the key and name as value.
     * 2. Add weekends value column.
     * 3. Add Total current/total day column.
     */
    appendFields() {
      console.log("Leaves: ", this.leaves);

      let cols = [];

      cols = ColumnService.generateLeaveCols(this.leaves);
      this.fields.push(...cols);
      this.fields.push(
        { key: "weekends.current", label: "X" },
        { key: "total.current", label: "TOT" }
      );
      this.modifiedLeaves = cols;

      console.log("Fields", this.fields);
    },

    /**
     * Handle page change event.
     * @param page value of page
     */
    changePage(page) {
      console.log(page);

      this.$store.commit("setTeacherYearlyAttendanceExportListPage", page);
      this.fetch();
    },

    /**
     * Handle date change.
     *
     * @param data
     */
    changeDate(data) {
      console.log(data);

      const objectSupport = require("dayjs/plugin/objectSupport");
      dayjs.extend(objectSupport);

      const date = dayjs({
        day: dayjs().format("D"),
        year: data.year,
      });

      this.$store.commit(
        "setTeacherYearlyAttendanceExportDateFilter",
        date.format()
      );

      this.fetch();
    },
  },
  computed: {
    ...mapGetters({
      items: "getTeacherYearlyAttendanceExportList",
      pagination: "getTeacherYearlyAttendanceExportListPagination",
      leaves: "getSchoolLeaveDetailList",
      aways: "getSchoolAwayDetailList",
    }),
  },
};
</script>

<style scoped></style>
